<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useListTable from '@/comp-functions/useListTable';
import { onMounted } from '@vue/composition-api'
import {formatNumber} from '@/utils/formatter'

export default {
  page: {
    title: "Vendor Configuration Port",
    meta: [{ name: "description", content: appConfig.description }]
  },
  setup () {
     const { 
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists } = useListTable({ url: false })

    
    const tableColumns = [
      { key: "vnc_vendorid", label: 'Airline', thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "vnc_portid", label: "Port", thClass: 'text-center', tdClass: 'text-center',  sortable: true },
      { key: "vnc_limit", label: 'Maximum Hour', thClass: 'text-center', tdClass: 'text-center', sortable: true },
      { key: "vnc_location", label: 'Drop Of Location', thClass: 'text-center', tdClass: 'text-center', sortable: true },
    ]
    
    const reloaddatalist = async () => {
      let url = '/master/vendorconfig_port'
      await fetchLists(url)
    }
    onMounted(() => {
      reloaddatalist()
    })
    return {
      formatNumber,
      select_label: "Select",
      tableColumns,
      reloaddatalist,

      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    }
  },
    methods: {
      onFiltered(filteredItems) {
        this.totalList = filteredItems.length;
        this.currentPage = 1;
      }
    },
    components: { Layout, PageHeader },
  data() {
    return {
      title: "Vendor Configuration Port",
      items: [],
      filterOn: []
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
               <div class="col-sm-12 col-md-6">
                
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-right">
                    Search:
                    <b-form-input
                      v-model="searchQuery"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                id="refListTable"
                ref="refListTable"
                no-provider-paging
                :fields="tableColumns"
                :items="itemLists"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="searchQuery"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
              >
              <template #cell(vnc_vendorid)="data">
                  {{ data.item.vnd_name }}
                </template>
               <template #cell(vnc_portid)="data">
                  {{ data.item.prt_initial }} -  {{ data.item.prt_locationname }}
                </template>
                <template #cell(vnc_limit)="data">
                  {{ data.item.vnc_configs.dropship.limithour}}
                </template> 
               <template #cell(vnc_location)="data">
                  {{ data.item.vnc_configs.dropship.locationname}}
                </template>

              </b-table>
            </div>
           <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                   Showing
                    <b-form-select class="col-sm-12 col-md-2" v-model="perPage" size="sm" :options="perPageOptions"></b-form-select>{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
                  </label>
                </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalList" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>